import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Snackbar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useMain } from '../hooks/useMain';
import TableCells from 'src/components/Tables/TableCells';
import TextInputs from 'src/components/inputs/TextInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { longFormatters } from 'date-fns';

export const TrimData = (data) => {
  if (data?.length < 25) return data;
  return data?.substring(0, 15) + '...' + data?.substring(data?.length - 10);
};

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  // membershipType: 'discountType',
  discount: {
    discountOnService: '',
    serviceDiscountType: '%',
    discountOnProduct: '',
    productDiscountType: '%',
    // discountOnPackages: '',
    // packageDiscountType: '%',
    minBillAmount: '',
  },
};

const DiscountType = ['%', 'INR'];

const DiscountTypeFieldData = ({ data, setData, getData, state }) => {
  const [openDialogue, setOpenDialogue] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updateFormData, setUpdateFormData] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { deleteMembership, updateMembership, setShowMessage, activeBranchId } = useMain();

  const handleCloseDialogue = () => {
    setOpenDialogue(false);
  };

  const handleDetails = (id) => {
    navigate(`/membershipdetail/${id}`, { state });
  };

  const handleUpdate = (item) => {
    setSelectedItemId(item._id);
    const { membershipName, membershipPrice, duration, rewardPointsOnPurchase, discount } = item;
    setUpdateFormData({
      ...initialValue,
      membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      discount: {
        ...initialValue.discount,
        ...discount,
      },
    });
    setOpenDialogue(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prev) => ({ ...prev, discount: { ...prev.discount, [name]: value }, }));
  };

  const handleUpdateDiscountTypeMembership = async () => {
    try {
      setLoading(true);
      const formData = {
        ...updateFormData,
        discount: {
          ...updateFormData.discount,
          discountOnService: updateFormData.discount.discountOnService || '',
          discountOnProduct: updateFormData.discount.discountOnProduct || '',
          // discountOnPackages: updateFormData.discount.discountOnPackages || '',
          minBillAmount: updateFormData.discount.minBillAmount || '',
        },
      };
      const res = await updateMembership(selectedItemId, formData, `?branchId=${activeBranchId}`);
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Membership Details updated successfully',
          messageType: 'success',
        });
        getData();
        setUpdateFormData(initialValue);
      } else {
        setShowMessage({
          message: res.message || 'Membership Details not updated',
          messageType: 'error',
        });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
      setOpenDialogue(false);
    }
  };

  const handleDeleteField = async (id) => {
    try {
      const res = await deleteMembership(id, `?branchId=${activeBranchId}`);
      if (res?.statusCode === 200) {
        setData(data?.filter((item) => item?._id !== id));
        setShowMessage({
          message: res.message || 'Membership deleted successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({ message: res.message || 'Membership not deleted', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setOpenDialogue(false);
    }
  };

  const handleDiscountTypeChange = (event, newDiscountType, type) => {
    if (newDiscountType !== null) {
      setUpdateFormData((prev) => ({
        ...prev,
        discount: {
          ...prev.discount,
          [type]: newDiscountType,
        },
      }));
    }
  };
  console.log(updateFormData, "upupupup") 

  return (
    <Box sx={{ overflow: 'auto', width: { xs: '450px', sm: 'auto' } }}>
      <table className="table-container mt-3">
        <thead className="table-thead">
          <tr>
            {[
              'Membership Name',
              'Membership Price',
              'Validity',
              'Discount on Services',
              'Discount on Products',
              // 'Discount on Packages',
              'Min Billed Amount',
              'Rewards Points',
              'Actions',
            ].map((h, index) => (
              <th key={index} style={{ width: '15%' }}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-tbody">
          {data.map((item) => (
            <tr key={item._id}>
              <td style={{ width: '100%' }}>{TrimData(item?.membershipName)}</td>
              <td style={{ width: '100%' }}>{item?.membershipPrice}</td>
              <td style={{ width: '100%' }}>{item?.duration}</td>
              <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnService} ${item?.discount?.serviceDiscountType}`}
              </td>
              <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnProduct} ${item?.discount?.productDiscountType}`}
              </td>
              {/* <td style={{ width: '100%' }}>
                {`${item?.discount?.discountOnPackages} ${item?.discount?.packageDiscountType}`}
              </td> */}
              <td style={{ width: '100%' }}>{item?.discount?.minBillAmount}</td>
              <td style={{ width: '100%' }}>{item?.rewardPointsOnPurchase}</td>
              <td style={{ width: '100%' }}>
                <TableCells
                  type="button"
                  handleDeleteField={handleDeleteField}
                  handleUpdate={() => handleUpdate(item)}
                  handleDetails={handleDetails}
                  field={item?._id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* DiscountType membership details dialog */}
      <Dialog open={openDialogue} onClose={handleCloseDialogue} maxWidth="md">
        <DialogTitle>DiscountType Membership Details</DialogTitle>
        <DialogContent>
          <DialogContentText>Here you can update membership details.</DialogContentText>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Membership Name'}
                  name={'membershipName'}
                  value={updateFormData?.membershipName}
                  handleChange={handleChange}
                  type={'text'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Duration (in Months)'}
                  name={'duration'}
                  min={'0'}
                  // placeholder={'0'}
                  value={updateFormData?.duration}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Membership Price'}
                  name={'membershipPrice'}
                  min={'1'}
                  value={updateFormData?.membershipPrice}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title={'Discount Service'}
                      name={'discountOnService'}
                      value={updateFormData?.discount?.discountOnService}
                      handleChange={handleDiscountChange}
                      type={'number'}
                      min={1}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginBottom: '30px', fontWeight: 'bold', color: 'black' }}>Discount Type</div>
                    {/* <Select
                      name="serviceDiscountType"
                      value={updateFormData?.discount?.serviceDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <ToggleButtonGroup
                      value={updateFormData?.discount?.serviceDiscountType}
                      exclusive
                      onChange={(event, newDiscountType) =>
                        handleDiscountTypeChange(event, newDiscountType, 'serviceDiscountType')
                      }
                      aria-label="service discount type"
                      fullWidth
                    >
                      <ToggleButton value="%" aria-label="percentage"                        sx={{
                            color: '#000', 
                            backgroundColor: '#e0e0e0', 
                            // borderRadius:'50%',
                            height:'3.3rem',
                            width:'4rem',
                            '&.Mui-selected': {
                              backgroundColor: '#6174DD',
                              color: '#fff', 
                              '&:hover': {
                                backgroundColor: '#6174DD', 
                              },
                            },
                            '&:not(.Mui-selected)': {
                              '&:hover': {
                                backgroundColor: '#d0d0d0', 
                              },
                            }
                          }}>
                        %
                      </ToggleButton>
                      <ToggleButton value="INR" aria-label="currency"                        sx={{
                            color: '#000', 
                            backgroundColor: '#e0e0e0', 
                            // borderRadius:'50%',

                            height:'3.3rem',
                            width:'4rem',
                            '&.Mui-selected': {
                              backgroundColor: '#6174DD',
                              color: '#fff', 
                              '&:hover': {
                                backgroundColor: '#6174DD', 
                              },
                            },
                            '&:not(.Mui-selected)': {
                              '&:hover': {
                                backgroundColor: '#d0d0d0', 
                              },
                            }
                          }}>
                        INR
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={12} md={6}>
                    <TextInputs
                      required={false}
                      title={'Discount Product'}
                      name={'discountOnProduct'}
                      value={updateFormData?.discount?.discountOnProduct}
                      handleChange={handleDiscountChange}
                      type={'number'}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{marginBottom: '30px', fontWeight: 'bold', color: 'black' }}>Discount Type</div>
                    {/* <Select
                      name="productDiscountType"
                      value={updateFormData?.discount?.productDiscountType}
                      onChange={handleDiscountChange}
                      fullWidth
                    >
                      {DiscountType?.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <ToggleButtonGroup
                      value={updateFormData?.discount?.productDiscountType}
                      exclusive
                      onChange={(event, newDiscountType) =>
                        handleDiscountTypeChange(event, newDiscountType, 'productDiscountType')
                      }
                      aria-label="product discount type"
                      fullWidth
                    >
                      <ToggleButton value="%" aria-label="percentage"                        sx={{
                            color: '#000', 
                            backgroundColor: '#e0e0e0', 
                            // borderRadius:'50%',

                            height:'3.3rem',
                            width:'4rem',
                            '&.Mui-selected': {
                              backgroundColor: '#6174DD',
                              color: '#fff', 
                              '&:hover': {
                                backgroundColor: '#6174DD', 
                              },
                            },
                            '&:not(.Mui-selected)': {
                              '&:hover': {
                                backgroundColor: '#d0d0d0', 
                              },
                            }
                          }}>
                        %
                      </ToggleButton>
                      <ToggleButton value="INR" aria-label="currency"                        sx={{
                            color: '#000', 
                            backgroundColor: '#e0e0e0', 
                            // borderRadius:'50%',

                            height:'3.3rem',
                            width:'4rem',
                            '&.Mui-selected': {
                              backgroundColor: '#6174DD',
                              color: '#fff', 
                              '&:hover': {
                                backgroundColor: '#6174DD', 
                              },
                            },
                            '&:not(.Mui-selected)': {
                              '&:hover': {
                                backgroundColor: '#d0d0d0', 
                              },
                            }
                          }}>
                        INR
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Reward Points'}
                  name={'rewardPointsOnPurchase'}
                  value={updateFormData?.rewardPointsOnPurchase}
                  handleChange={handleChange}
                  type={'number'}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInputs
                  required={false}
                  title={'Minimum Bill Amount'}
                  name={'minBillAmount'}
                  value={updateFormData?.discount?.minBillAmount}
                  handleChange={handleDiscountChange}
                  type={'number'}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <ShowLoader />
          ) : (
            <>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleCloseDialogue}
                >
                  Cancel
                </Button>
              </div>
              <div style={{ width: '200px', margin: '3rem auto' }}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  sx={{
                    background: '#6174DD',
                  }}
                  onClick={handleUpdateDiscountTypeMembership}
                >
                  Update
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DiscountTypeFieldData;
