// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import FieldData from './FieldData';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import SearchImg from '../../assets/search.svg';

// const filterActiveStatusWise = [
//   { label: 'APPROVED', value: 'APPROVED' },
//   { label: 'PENDING', value: 'PENDING' },
// ];
// const AllBranch = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [search, setSearch] = useState('');
//   const [activeStatus, setActiveStatus] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const { ownerData, fetchAllBranch } = useMain();

//   const[filterData, setFilterData] = useState(null)
//   console.log(filterData);

//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setActiveStatus('');
//     if (clear) {
//       getData('clear');
//       setClear(false);
//     }
//   };

//   const onApplyHandler = () => {
//     if (search) {
//       getData();
//       setClear(true);
//     }
//   };

//   const getData = async (key) => {
//     let queryParams = `?page=${page}&limit=${limit}${
//       activeStatus ? `&activeStatus=${activeStatus}` : ''
//     }${search ? `&search=${search}` : ''}`;

//     // if (key) queryParams = `?page=${page}&limit=${limit}`;

//     setLoading(true);
//     try {
//       const res = await fetchAllBranch(ownerData._id);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       setData([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (ownerData._id) {
//       setClear(true);
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [ownerData._id]);


//   useEffect(() => {
//     applyFilters()
//   }, [limit, page, activeStatus, search]);

//   const applyFilters = () => {

//     console.log(data);
//     console.log(search);
//     let filteredData = data.filter(item => {
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (activeStatus) {
//         isStatusMatch = item.appointmentStatus === appointmentStatus;
//       }

//       if (search) {
//         isNameMatch = item.clientName.toLowerCase().includes(search.toLowerCase()) 
//       }

//       return isStatusMatch && isNameMatch;
//     });

//     // Applying pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilterData(filteredData)
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="Branches" description="this contains all products">
//       <DashboardCard2 title="Branches">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                   // width:'100px'
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2}></Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Active Status</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={activeStatus}
//                   onChange={(e) => setActiveStatus(e.target.value)}
//                 >
//                   {filterActiveStatusWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               {/* <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   background: '#6174DD',
//                   height: '40px',
//                   width: '100%',
//                 }}
//                 onClick={onApplyHandler}
//               >
//                 Apply
//               </Button> */}

//               <Button
//                 variant="contained"
//                 color="primary"
//                 target="_blank"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {loading ? (
//           <ShowLoader />
//         // ) : data.length > 0 ? (
//         ) : filterData && filterData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData data={filterdata} setData={setData} getData={getData} />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={data.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBranch;


// import React, { useEffect, useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import FieldData from './FieldData';
// import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import DashboardCard2 from 'src/components/shared/DashboardCard2';
// import SearchImg from '../../assets/search.svg';
// import { useNavigate } from 'react-router';

// const filterActiveStatusWise = [
//   { label: 'APPROVED', value: 'APPROVED' },
//   { label: 'PENDING', value: 'PENDING' },
// ];

// const AllBranch = () => {
//   const { ownerData, fetchAllBranch } = useMain();

//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [search, setSearch] = useState('');
//   const [activeStatus, setActiveStatus] = useState('');
//   const [limit, setLimit] = useState(10);
//   const [page, setPage] = useState(1);
//   const [clear, setClear] = useState(false);
//   const [filteredData, setFilteredData] = useState([]); // Corrected initialization

//   const navigate = useNavigate()
//   const clearDataHandler = () => {
//     setLimit(10);
//     setPage(1);
//     setSearch('');
//     setActiveStatus('');
//     setClear(true);
//   };

//   const getData = async () => {
//     let queryParams = `?page=${page}&limit=${limit}${activeStatus ? `&activeStatus=${activeStatus}` : ''
//       }${search ? `&search=${search}` : ''}`;

//     setLoading(true);
//     try {
//       const res = await fetchAllBranch(ownerData._id);
//       if (res.statusCode === 200) {
//         setData(res.data || []);
//       } else {
//         setData([]);
//       }
//     } catch (error) {
//       setData([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (ownerData._id) {
//       getData();
//     }
//     return () => {
//       setData([]);
//     };
//   }, [ownerData._id, limit, page, activeStatus, search]); // Added dependencies

//   useEffect(() => {
//     applyFilters();
//   }, [data, search, activeStatus, limit, page]); // Updated dependencies

//   const applyFilters = () => {
//     let filteredData = data.filter(item => {
//       let isStatusMatch = true;
//       let isNameMatch = true;

//       if (activeStatus) {
//         isStatusMatch = item.activeStatus === activeStatus;
//       }

//       if (search) {
//         isNameMatch = item.branchName.toLowerCase().includes(search.toLowerCase());
//       }

//       return isStatusMatch && isNameMatch;
//     });

//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//     filteredData = filteredData.slice(startIndex, endIndex);
//     setFilteredData(filteredData);
//   };

//   const handleLimitChange = (e) => {
//     setLimit(e.target.value);
//     setPage(1);
//   };

//   return (
//     <PageContainer title="Branches" description="This contains all products">
//       <DashboardCard2 title="Branches">
//         <Grid container style={{ gap: '10px' }}>
//           <Grid container justifyContent="space-between">
//             <Grid item xs={2}>
//               <InputLabel>Show per page</InputLabel>
//               <Select
//                 style={{
//                   borderRadius: '7px',
//                   border: '0.5px solid #6174DD',
//                   padding: '4px 12px',
//                   background: '#FFF',
//                   height: '40px',
//                 }}
//                 value={limit}
//                 onChange={handleLimitChange}
//               >
//                 <MenuItem value={10}>10</MenuItem>
//                 <MenuItem value={20}>20</MenuItem>
//                 <MenuItem value={50}>50</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Active Status</InputLabel>
//                 <Select
//                   style={{
//                     borderRadius: '7px',
//                     border: '0.5px solid #6174DD',
//                     padding: '4px 12px',
//                     background: '#FFF',
//                     height: '40px',
//                     width: '100%',
//                   }}
//                   value={activeStatus}
//                   onChange={(e) => setActiveStatus(e.target.value)}
//                 >
//                   {filterActiveStatusWise.map((item, index) => (
//                     <MenuItem key={index} value={item.value}>
//                       {item.label}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </div>
//             </Grid>
//             <Grid item xs={2}>
//               <div>
//                 <InputLabel>Search</InputLabel>
//                 <div className="search-input">
//                   <img src={SearchImg} alt="" />
//                   <input
//                     type="text"
//                     placeholder=""
//                     value={search}
//                     onChange={(e) => setSearch(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </Grid>
//             <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
//               <Button
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   height: '40px',
//                   width: '100%',
//                   background: '#ff4d4d',
//                   '&:hover': {
//                     background: '#ff6666',
//                   },
//                 }}
//                 onClick={clearDataHandler}
//               >
//                 Clear
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//         {loading ? (
//           <ShowLoader />
//         ) : filteredData && filteredData.length > 0 ? (
//           <>
//             <Box>
//               <Grid container spacing={3}>
//                 <Grid item xs={12} lg={12}>
//                   <FieldData data={filteredData} setData={setData} getData={getData} />
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ) : (
//           'No data found'
//         )}
//         <Box display={'flex'} justifyContent={'flex-end'}>
//           <Pagination
//             count={filteredData && filteredData?.length < limit ? page : page + 1}
//             page={page}
//             color="primary"
//             onChange={(e, page) => setPage(page)}
//           />
//         </Box>
//       </DashboardCard2>
//     </PageContainer>
//   );
// };

// export default AllBranch;

//pagination correction
import React, { useEffect, useState, useCallback } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import FieldData from './FieldData';
import { Box, Button, Grid, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import DashboardCard2 from 'src/components/shared/DashboardCard2';
import SearchImg from '../../assets/search.svg';
import { useNavigate } from 'react-router';

const filterActiveStatusWise = [
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'PENDING', value: 'PENDING' },
];

const AllBranch = () => {
  const { ownerData, fetchAllBranch } = useMain();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [clear, setClear] = useState(false);

  const navigate = useNavigate();
 
  const fetchAllBranches = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchAllBranch(ownerData._id);
      if (res.statusCode === 200) {
        setData(res.data || []);
        setFilteredData(res.data || []);
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      setData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  }, [fetchAllBranch, ownerData._id]);

  useEffect(() => {
    if (ownerData._id) {
      fetchAllBranches();
    }
  }, [fetchAllBranches, ownerData._id]);

  useEffect(() => {
    applyFilters();
  }, [data, search, activeStatus, limit, page]);

  const applyFilters = () => {
    let filtered = data.filter(item => {
      let isStatusMatch = true;
      let isNameMatch = true;

      if (activeStatus) {
        isStatusMatch = item.activeStatus === activeStatus;
      }

      if (search) {
        isNameMatch = item.branchName.toLowerCase().includes(search.toLowerCase());
      }

      return isStatusMatch && isNameMatch;
    });

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    filtered = filtered.slice(startIndex, endIndex);

    setFilteredData(filtered);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const clearDataHandler = () => {
    setLimit(10);
    setPage(1);
    setSearch('');
    setActiveStatus('');
    setClear(true);
    fetchAllBranches(); // Re-fetch all data when cleared
  };

  return (
    <PageContainer title="Branches" description="This contains all products">
      <DashboardCard2 title="Branches">
        <Grid container style={{ gap: '10px' }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={2}>
              <InputLabel>Show per page</InputLabel>
              <Select
                style={{
                  borderRadius: '7px',
                  border: '0.5px solid #6174DD',
                  padding: '4px 12px',
                  background: '#FFF',
                  height: '40px',
                }}
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </Grid>
            {/* <Grid item xs={2}> */}
            <Grid item xs={1.3} marginLeft={48}>
              <div>
                <InputLabel>Active Status</InputLabel>
                <Select
                  style={{
                    borderRadius: '7px',
                    border: '0.5px solid #6174DD',
                    padding: '4px 12px',
                    background: '#FFF',
                    height: '40px',
                    width: '100%',
                  }}
                  value={activeStatus}
                  onChange={(e) => setActiveStatus(e.target.value)}
                >
                  {filterActiveStatusWise.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item xs={1.3} marginLeft={10}>
              <div>
                <InputLabel>Search</InputLabel>
                <div className="search-input">
                  <img src={SearchImg} alt="" />
                  <input
                    type="text"
                    placeholder=""
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={2} display="flex" gap="20px" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: '40px',
                  // width: '100%',
                  width: '60%',
                  marginLeft: '5rem',
                  background: '#ff4d4d',
                  '&:hover': { background: '#ff6666' },
                }}
                onClick={clearDataHandler}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <ShowLoader />
        ) : filteredData && filteredData.length > 0 ? (
          <>
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <FieldData data={filteredData} setData={setData} getData={fetchAllBranches} />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          'No data found'
        )}
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Pagination
            count={Math.ceil(data.length / limit)}
            page={page}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        </Box>
      </DashboardCard2>
    </PageContainer>
  );
};

export default AllBranch;
