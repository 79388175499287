// import React, { useState } from 'react';
// import { Box, Button, Grid, MenuItem, Select } from '@mui/material';
// import TextInputs from 'src/components/inputs/TextInputs';
// import { useMain } from '../hooks/useMain';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import { useEffect } from 'react';

// const initialValue = {
//   membershipName: '',
//   membershipPrice: '',
//   duration: '',
//   rewardPointsOnPurchase: '',
//   discount: {
//     discountOnService: '',
//     serviceDiscountType: '%',
//     discountOnProduct: '',
//     productDiscountType: '%',
//     minBillAmount: '',
//     availableCount: '',
//   },
// };

// const initialFormValidation = {
//   membershipNameValidation: false,
//   membershipPriceValidation: false,
//   durationValidation: false,
//   discountOnServiceValidation: false,
//   discountOnProductValidation: false,
//   minBillAmountValidation: false,
//   availableCountValidation: false,
//   membershipTypeValidation: false,
// };

// const DiscountType = ['%', 'INR'];

// const DiscountMembership = ({ membershipType = 'discountType' }) => {
//   const { createMembership, activeBranchId, setShowMessage } = useMain();

//   const [formData, setFormData] = useState({ ...initialValue, membershipType });
//   const [formValidation, setFormValidation] = useState(initialFormValidation);
//   const [loading, setLoading] = useState(false);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//     setFormValidation((prevFormValidation) => ({
//       ...prevFormValidation,
//       [`${name}Validation`]: !value.trim(),
//     }));
//   };

//   const handleDiscountChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       discount: {
//         ...prevFormData.discount,
//         [name]: value,
//       },
//     }));
//     setFormValidation((prevFormValidation) => ({
//       ...prevFormValidation,
//       [`${name}Validation`]: !(Number(value) > 0),
//     }));
//   };

//   const handleSubmit = async () => {
//     const {
//       membershipName,
//       membershipPrice,
//       duration,
//       rewardPointsOnPurchase,
//       discount,
//       membershipType,
//     } = formData;

//     const validation = {
//       membershipNameValidation: !membershipName.trim(),
//       membershipPriceValidation: !membershipPrice || !(Number(membershipPrice) > 0),
//       durationValidation: !duration || !(Number(duration) > 0),
//       discountOnServiceValidation: !(Number(discount.discountOnService) > 0),
//       discountOnProductValidation: !(Number(discount.discountOnProduct) > 0),
//       minBillAmountValidation: !(Number(discount.minBillAmount) > 0),
//       availableCountValidation: !(Number(discount.availableCount) > 0),
//       membershipTypeValidation: !membershipType.trim(),
//     };

//     setFormValidation((prev) => ({ ...prev, ...validation }));

//     if (Object.values(validation).some((item) => item)) {
//       setShowMessage({
//         message: 'Please fill out all required fields correctly.',
//         messageType: 'error',
//       });
//       return;
//     }
//     try {
//       setLoading(true);
//       const res = await createMembership(
//         JSON.stringify({
//           ...formData,
//           membershipName,
//           membershipPrice: Number(membershipPrice),
//           duration: Number(duration),
//           rewardPointsOnPurchase: rewardPointsOnPurchase ? Number(rewardPointsOnPurchase) : 0,
//           discount: {
//             ...discount,
//             discountOnService: Number(discount.discountOnService),
//             discountOnProduct: Number(discount.discountOnProduct),
//             minBillAmount: Number(discount.minBillAmount),
//             availableCount: Number(discount.availableCount),
//           },
//           membershipType,
//           branchDetails: activeBranchId,
//         }),
//       );

//       if (res?.statusCode === 200) {
//         setFormData({ ...initialValue, membershipType });
//         setFormValidation({ ...initialFormValidation });
//         setShowMessage({
//           message: res.message || 'Discount Type membership created successfully',
//           messageType: 'success',
//         });
//       } else {
//         setShowMessage({
//           message: res.message || 'Membership not created',
//           messageType: 'error',
//         });
//       }
//     } catch (error) {
//       console.error('Error creating membership:', error);
//       setShowMessage({
//         message: error.message || 'Error creating membership',
//         messageType: 'error',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // This logic disables the mouse wheel event
//   useEffect(() => {
//     const handleWheel = (e) => {
//       if (document.activeElement.type === 'number') {
//         document.activeElement.blur();
//       }
//     };

//     window.addEventListener('wheel', handleWheel);
//     return () => {
//       window.removeEventListener('wheel', handleWheel);
//     };
//   }, []);

//   return (
//     <div>
//       <Box style={{ marginTop: '20px' }}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               title={'Membership Name'}
//               name={'membershipName'}
//               value={formData?.membershipName}
//               handleChange={handleChange}
//               type={'text'}
//               placeholder={'Membership Name'}
//               fieldValidation={formValidation.membershipNameValidation}
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               title={'Duration (in Months)'}
//               name={'duration'}
//               placeholder="0"
//               min={'0'}
//               value={formData?.duration}
//               handleChange={handleChange}
//               type={'number'}
//               fieldValidation={formValidation.durationValidation}
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               title={'Membership Price'}
//               name={'membershipPrice'}
//               placeholder="0"
//               value={formData?.membershipPrice}
//               handleChange={handleChange}
//               type={'number'}
//               min={'0'}
//               fieldValidation={formValidation.membershipPriceValidation}
//             />
//           </Grid>
//         </Grid>
//       </Box>

//       <Box style={{ marginTop: '20px' }}>
//         <Grid container spacing={3}>
//           <Grid item xs={12} md={4}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Discount on Service'}
//                   name={'discountOnService'}
//                   placeholder="0"
//                   value={formData?.discount?.discountOnService}
//                   handleChange={handleDiscountChange}
//                   type={'number'}
//                   min={1}
//                   fieldValidation={formValidation.discountOnServiceValidation}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <div style={{ marginBottom: '7px', fontWeight: 'bold', color: 'black' }}>
//                   Discount Type
//                 </div>
//                 <Select
//                   name="serviceDiscountType"
//                   value={formData?.discount?.serviceDiscountType}
//                   onChange={handleDiscountChange}
//                   fullWidth
//                 >
//                   {DiscountType.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Discount on Product'}
//                   name={'discountOnProduct'}
//                   placeholder="0"
//                   value={formData?.discount?.discountOnProduct}
//                   handleChange={handleDiscountChange}
//                   type={'number'}
//                   min={'1'}
//                   fieldValidation={formValidation.discountOnProductValidation}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <div style={{ marginBottom: '7px', fontWeight: 'bold', color: 'black' }}>
//                   Discount Type
//                 </div>
//                 <Select
//                   name="productDiscountType"
//                   value={formData?.discount?.productDiscountType}
//                   onChange={handleDiscountChange}
//                   fullWidth
//                 >
//                   {DiscountType.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//             </Grid>
//           </Grid>
//           {/* <Grid item xs={12} md={4}>
//             <Grid container spacing={2} >
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Discount On Package'}
//                   name={'discountOnPackages'}
//                   placeholder="0"
//                   value={formData?.discount?.discountOnPackages}
//                   handleChange={handleDiscountChange}
//                   type={'number'}
//                   fieldValidation={discountOnPackagesvalidation && formValidation.discountOnPackagesvalidation}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <div style={{ marginBottom: '7px', fontWeight: 'bold' }}>
//                   Discount Type <ReqField />
//                 </div>
//                 <Select
//                   name="packageDiscountType"
//                   value={formData?.discount?.packageDiscountType}
//                   onChange={handleDiscountChange}
//                   fullWidth
//                 >
//                   {DiscountType.map((type) => (
//                     <MenuItem key={type} value={type}>
//                       {type}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//             </Grid>
//           </Grid> */}
//         </Grid>
//       </Box>
//       <Box style={{ marginTop: '20px' }}>
//         <Grid container spacing={3}>
//           {/* <Grid item xs={12} md={4}>
//             <TextInputs
//               required={false}
//               title={'Reward Points'}
//               name={'rewardPointsOnPurchase'}
//               // placeholder="0"
//               value={formData?.rewardPointsOnPurchase}
//               handleChange={handleChange}
//               type={'number'}
//             />
//           </Grid> */}
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               title={'Minimum Bill Amount'}
//               name={'minBillAmount'}
//               placeholder="0"
//               value={formData?.discount?.minBillAmount}
//               handleChange={handleDiscountChange}
//               type={'number'}
//               min={'0'}
//               fieldValidation={formValidation.minBillAmountValidation}
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               title={'Available Count'}
//               name={'availableCount'}
//               // placeholder="0"
//               value={formData?.discount?.availableCount}
//               handleChange={handleDiscountChange}
//               type={'number'}
//               min={'1'}
//               fieldValidation={formValidation.availableCountValidation}
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <TextInputs
//               // required={false}
//               title={'Reward Points on Purchase'}
//               name={'rewardPointsOnPurchase'}
//               placeholder="0"
//               value={formData?.rewardPointsOnPurchase}
//               handleChange={handleChange}
//               type={'number'}
//               min={'0'}
//             />
//           </Grid>
//         </Grid>
//       </Box>

//       <Box style={{ marginTop: '20px' }}>
//         <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
//           Create Membership
//         </Button>
//       </Box>
//     </div>
//   );
// };

// export default DiscountMembership;

//*new code
import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TextInputs from 'src/components/inputs/TextInputs';
import { useMain } from '../hooks/useMain';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';

const initialValue = {
  membershipName: '',
  membershipPrice: '',
  duration: '',
  rewardPointsOnPurchase: '',
  discount: {
    discountOnService: '',
    serviceDiscountType: '%',
    discountOnProduct: '',
    productDiscountType: '%',
    minBillAmount: '',
    availableCount: '',
  },
};

const initialFormValidation = {
  membershipNameValidation: false,
  membershipPriceValidation: false,
  durationValidation: false,
  discountOnServiceValidation: false,
  discountOnProductValidation: false,
  minBillAmountValidation: false,
  availableCountValidation: false,
  // membershipTypeValidation: false,
};

const DiscountType = ['%', 'INR'];

const DiscountMembership = ({ membershipType = 'discountType' }) => {
  const { createMembership, activeBranchId, setShowMessage } = useMain();

  const [formData, setFormData] = useState({ ...initialValue, membershipType });
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormValidation((prevFormValidation) => ({
      ...prevFormValidation,
      [`${name}Validation`]: !value.trim(),
    }));
  };

  const handleDiscountChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      discount: {
        ...prevFormData.discount,
        [name]: value,
      },
    }));
    setFormValidation((prevFormValidation) => ({
      ...prevFormValidation,
      [`${name}Validation`]: !(Number(value) > 0),
    }));
  };

  // const handleDiscountTypeChange = (type, newType) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     discount: {
  //       ...prevFormData.discount,
  //       [type]: newType,
  //     },
  //   }));
  // };

  const handleDiscountTypeChange = (event, newDiscountType, typeKey) => {
    if (newDiscountType !== null) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        discount: {
          ...prevFormData.discount,
          [typeKey]: newDiscountType,
        },
      }));
    }
  };

  const handleSubmit = async () => {
    const {
      membershipName,
      membershipPrice,
      duration,
      rewardPointsOnPurchase,
      discount,
      membershipType,
    } = formData;

    const validation = {
      membershipNameValidation: !membershipName.trim(),
      membershipPriceValidation: !membershipPrice || !(Number(membershipPrice) > 0),
      durationValidation: !duration || !(Number(duration) > 0),
      discountOnServiceValidation: !(Number(discount.discountOnService) > 0),
      discountOnProductValidation: !(Number(discount.discountOnProduct) > 0),
      minBillAmountValidation: !(Number(discount.minBillAmount) > 0),
      availableCountValidation: !(Number(discount.availableCount) > 0),
      membershipTypeValidation: !membershipType.trim(),
    };

    setFormValidation((prev) => ({ ...prev, ...validation }));

    if (Object.values(validation).some((item) => item)) {
      setShowMessage({
        message: 'Please fill out all required fields correctly.',
        messageType: 'error',
      });
      return;
    }
    try {
      setLoading(true);
      const res = await createMembership(
        JSON.stringify({
          ...formData,
          membershipName,
          membershipPrice: Number(membershipPrice),
          duration: Number(duration),
          rewardPointsOnPurchase: rewardPointsOnPurchase ? Number(rewardPointsOnPurchase) : 0,
          discount: {
            ...discount,
            discountOnService: Number(discount.discountOnService),
            discountOnProduct: Number(discount.discountOnProduct),
            minBillAmount: Number(discount.minBillAmount),
            availableCount: Number(discount.availableCount),
          },
          membershipType,
          branchDetails: activeBranchId,
        }),
      );

      if (res?.statusCode === 200) {
        setFormData({ ...initialValue, membershipType });
        setFormValidation({ ...initialFormValidation });
        setShowMessage({
          message: res.message || 'Discount Type membership created successfully',
          messageType: 'success',
        });
      } else {
        setShowMessage({
          message: res.message || 'Membership not created',
          messageType: 'error',
        });
      }
    } catch (error) {
      console.error('Error creating membership:', error);
      setShowMessage({
        message: error.message || 'Error creating membership',
        messageType: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  console.log(formData, "etwrte");

  return (
    <div>
      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Membership Name'}
              name={'membershipName'}
              value={formData?.membershipName}
              handleChange={handleChange}
              type={'text'}
              placeholder={'Membership Name'}
              fieldValidation={formValidation.membershipNameValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Duration (in Months)'}
              name={'duration'}
              placeholder="0"
              min={'0'}
              value={formData?.duration}
              handleChange={handleChange}
              type={'number'}
              fieldValidation={formValidation.durationValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Membership Price'}
              name={'membershipPrice'}
              placeholder="0"
              value={formData?.membershipPrice}
              handleChange={handleChange}
              type={'number'}
              min={'0'}
              fieldValidation={formValidation.membershipPriceValidation}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Discount on Service'}
                  name={'discountOnService'}
                  placeholder="0"
                  value={formData?.discount?.discountOnService}
                  handleChange={handleDiscountChange}
                  type={'number'}
                  min={1}
                  fieldValidation={formValidation.discountOnServiceValidation}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ marginBottom: '7px', fontWeight: 'bold', color: 'black' }}>
                  Discount Type
                </div>
                <ToggleButtonGroup
                  value={formData?.discount?.serviceDiscountType}
                  exclusive
                  // onChange={(event, newType) => handleDiscountTypeChange('serviceDiscountType', newType)}
                  // aria-label="service discount type"
                  // fullWidth
                  onChange={(e, newType) => handleDiscountTypeChange(e, newType, 'serviceDiscountType')}
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    backgroundColor: '#f0f0f0',
                    width: '130px'
                  }}
                >
                  {DiscountType.map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      // aria-label={type}
                      sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        height: '3.3rem',
                        width: '130px',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Discount on Product'}
                  name={'discountOnProduct'}
                  placeholder="0"
                  value={formData?.discount?.discountOnProduct}
                  handleChange={handleDiscountChange}
                  type={'number'}
                  min={'1'}
                  fieldValidation={formValidation.discountOnProductValidation}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ marginBottom: '7px', fontWeight: 'bold', color: 'black' }}>
                  Discount Type
                </div>
                <ToggleButtonGroup
                  value={formData?.discount?.productDiscountType}
                  exclusive
                  // onChange={(event, newType) => handleDiscountTypeChange('productDiscountType', newType)}
                  // aria-label="product discount type"
                  // fullWidth
                  onChange={(e, newType) => handleDiscountTypeChange(e, newType, 'productDiscountType')}
                  sx={{
                    borderRadius: '4px',
                    height: '50px',
                    backgroundColor: '#f0f0f0',
                    width: '130px'
                  }}
                >
                  {DiscountType.map((type) => (
                    <ToggleButton
                      key={type}
                      value={type}
                      // aria-label={type}
                      sx={{
                        color: '#000',
                        backgroundColor: '#e0e0e0',
                        height: '3.3rem',
                        width: '130px',
                        '&.Mui-selected': {
                          backgroundColor: '#6174DD',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6174DD',
                          },
                        },
                        '&:not(.Mui-selected)': {
                          '&:hover': {
                            backgroundColor: '#d0d0d0',
                          },
                        }
                      }}
                    >
                      {type}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Minimum Bill Amount'}
              name={'minBillAmount'}
              placeholder="0"
              value={formData?.discount?.minBillAmount}
              handleChange={handleDiscountChange}
              type={'number'}
              min={'0'}
              fieldValidation={formValidation.minBillAmountValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Available Count'}
              name={'availableCount'}
              value={formData?.discount?.availableCount}
              handleChange={handleDiscountChange}
              type={'number'}
              min={'1'}
              fieldValidation={formValidation.availableCountValidation}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInputs
              title={'Reward Points on Purchase'}
              name={'rewardPointsOnPurchase'}
              placeholder="0"
              value={formData?.rewardPointsOnPurchase}
              handleChange={handleChange}
              type={'number'}
              min={'0'}
            />
          </Grid>
        </Grid>
      </Box>

      <Box style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
          Create Membership
        </Button>
      </Box>
    </div>
  );
};

export default DiscountMembership;
