// import React, { useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard from 'src/components/shared/DashboardCard';
// import { Button, Grid, MenuItem, Select } from '@mui/material';
// import { Box } from '@mui/system';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import { useMain } from '../hooks/useMain';
// import TextInputs from 'src/components/inputs/TextInputs';
// import RadioInputs from 'src/components/inputs/RadioInputs';
// import imageCompression from 'browser-image-compression';
// import TimeSlotSelector from 'src/components/inputs/TimeSlotSelector';
// import { useEffect } from 'react';

// const initialValue = {
//   name: '',
//   dob: '',
//   phone: '',
//   mail: '',
//   start: '',
//   end: '',
//   salary: '',
//   emergencyContact: '',
//   emergencyName: '',
//   gender: '',
//   dateOfJoining: '',
//   userType: '',
//   department: '',
//   incentive: '',
//   incentiveType: 'INR',
//   target: '',
//   img1: null,
//   img2: null,
//   address: '',
// };

// const initialFormValidation = {
//   nameValidation: false,
//   phoneValidation: false,
//   genderValidation: false,
//   mailValidation: false,
// };

// const AddStaff = () => {
//   const { createStaff, activeBranchId, setShowMessage } = useMain();

//   const [formData, setFormData] = useState(initialValue);
//   const [formValidation, setFormValidation] = useState(initialFormValidation);

//   const [loading, setLoading] = useState(false);

//   const nameValidation = !formData.name.trim();
//   const phoneValidation = !formData.phone || formData.phone.length !== 10;
//   const genderValidation = !formData.gender;
//   const mailValidation = !formData.mail;

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleImage = async (e) => {
//     const imageFile = e.target?.files[0];

//     if (imageFile) {
//       const maxSizeInBytes = 1024 * 1024; // 1MB
//       const currentSizeInBytes = imageFile.size;

//       if (currentSizeInBytes <= maxSizeInBytes) {
//         // If the image is already smaller than 1MB, no need to compress
//         setFormData({ ...formData, [e.target.name]: imageFile });
//       } else {
//         // Image needs compression
//         try {
//           const options = {
//             maxSizeMB: 1,
//             useWebWorker: true,
//           };
//           const compressedImage = await imageCompression(imageFile, options);
//           setFormData({ ...formData, [e.target.name]: compressedImage });
//         } catch (error) {
//           setShowMessage({
//             message: 'Error compressing image. Please try again.',
//             messageType: 'error',
//           });
//         }
//       }
//     }
//   };

//   // This logic disables the mouse wheel event
//   useEffect(() => {
//     const handleWheel = (e) => {
//       if (document.activeElement.type === 'number') {
//         document.activeElement.blur();
//       }
//     };

//     window.addEventListener('wheel', handleWheel);
//     return () => {
//       window.removeEventListener('wheel', handleWheel);
//     };
//   }, []);

//   const handleSubmit = async () => {
//     const {
//       name,
//       dob,
//       phone,
//       mail,
//       start,
//       end,
//       salary,
//       emergencyContact,
//       emergencyName,
//       gender,
//       dateOfJoining,
//       userType,
//       department,
//       incentive,
//       incentiveType,
//       target,
//       img1,
//       img2,
//       address,
//     } = formData;

//     const validation = {
//       nameValidation: !name.trim(),
//       phoneValidation: !phone || formData.phone.length !== 10,
//       genderValidation: !gender,
//       mailValidation: !mail,
//     };

//     setFormValidation((pre) => ({ ...pre, ...validation }));

//     if (Object.values(validation).some((item) => item)) return;

//     const formDataUpdated = new FormData();
//     formDataUpdated.append('name', name);
//     formDataUpdated.append('dob', dob);
//     formDataUpdated.append('gender', gender);
//     formDataUpdated.append('phone', phone);
//     formDataUpdated.append('mail', mail);
//     formDataUpdated.append('workingHrs', JSON.stringify({ start, end }));
//     formDataUpdated.append('salary', salary);
//     formDataUpdated.append(
//       'emergencyDetails',
//       JSON.stringify({ contactNo: emergencyContact, name: emergencyName }),
//     );
//     formDataUpdated.append('address', address);
//     formDataUpdated.append('dateOfJoining', dateOfJoining);
//     formDataUpdated.append('userType', userType);
//     formDataUpdated.append('department', department);
//     formDataUpdated.append('incentive', incentive);
//     formDataUpdated.append('incentiveType', incentiveType);
//     formDataUpdated.append('target', target);
//     formDataUpdated.append('branchDetails', activeBranchId);
//     if (img1) {
//       formDataUpdated.append('images', img1);
//     }
//     if (img2) {
//       formDataUpdated.append('images', img2);
//     }

//     try {
//       setLoading(true);
//       const res = await createStaff(formDataUpdated);
//       if (res?.statusCode === 200) {
//         setShowMessage({
//           message: res.message || 'Staff created successfully',
//           messageType: 'success',
//         });
//         setFormData((pre) => ({ ...initialValue }));
//         setFormValidation((pre) => ({ ...initialFormValidation }));
//       } else {
//         setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };
//   return (
//     <PageContainer title="Add Staff" description="this is add Staff">
//       <DashboardCard title="Add Staff">
//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 title={'Name'}
//                 name={'name'}
//                 value={formData?.name}
//                 handleChange={handleChange}
//                 type={'text'}
//                 placeholder={'Name'}
//                 fieldValidation={nameValidation && formValidation.nameValidation}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 title={'Contact Number'}
//                 name={'phone'}
//                 value={formData?.phone}
//                 handleChange={handleChange}
//                 type={'number'}
//                 placeholder={'Mobile No.'}
//                 fieldValidation={phoneValidation && formValidation.phoneValidation}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 title={'Email Address'}
//                 name={'mail'}
//                 value={formData?.mail}
//                 handleChange={handleChange}
//                 type={'email'}
//                 placeholder={'Email'}
//                 fieldValidation={mailValidation && formValidation.mailValidation}
//               />
//             </Grid>
//           </Grid>
//         </Box>

//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4}>
//               <RadioInputs
//                 title={'Gender'}
//                 name={'gender'}
//                 value={formData?.gender}
//                 handleChange={handleChange}
//                 options={[
//                   { title: 'M', value: 'Male' },
//                   { title: 'F', value: 'Female' },
//                 ]}
//                 fieldValidation={genderValidation && formValidation.genderValidation}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Date of Birth'}
//                 name={'dob'}
//                 value={formData?.dob}
//                 handleChange={handleChange}
//                 type={'date'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Address'}
//                 name={'address'}
//                 value={formData?.address}
//                 handleChange={handleChange}
//                 type={'text'}
//                 placeholder={'Address'}
//               />
//             </Grid>
//           </Grid>
//         </Box>

//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Emergency Contact Number'}
//                 name={'emergencyContact'}
//                 value={formData?.emergencyContact}
//                 handleChange={handleChange}
//                 type={'number'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Emergency Contact Person'}
//                 name={'emergencyName'}
//                 value={formData?.emergencyName}
//                 handleChange={handleChange}
//                 type={'text'}
//               />
//             </Grid>

//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Monthly Salary'}
//                 name={'salary'}
//                 min={'0'}
//                 // placeholder={'0'}
//                 value={formData?.salary}
//                 handleChange={handleChange}
//                 type={'number'}
//               />
//             </Grid>
//           </Grid>
//         </Box>

//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={4}>
//               <Box>
//                 <Grid container spacing={3}>
//                   <Grid item xs={6}>
//                     <TimeSlotSelector
//                       required={false}
//                       title={'Shift Time'}
//                       name={'start'}
//                       value={formData?.start}
//                       handleChange={handleChange}
//                     />
//                   </Grid>
//                   <Grid item xs={6} mt={3}>
//                     <TimeSlotSelector
//                       required={false}
//                       // title={'end time'}
//                       name={'end'}
//                       value={formData?.end}
//                       handleChange={handleChange}
//                     />
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'User Type'}
//                 name={'userType'}
//                 value={formData?.userType}
//                 handleChange={handleChange}
//                 type={'text'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Department'}
//                 name={'department'}
//                 value={formData?.department}
//                 handleChange={handleChange}
//                 type={'text'}
//               />
//             </Grid>
//           </Grid>
//         </Box>

//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Target Amount'}
//                 name={'target'}
//                 value={formData?.target}
//                 min={'0'}
//                 handleChange={handleChange}
//                 type={'number'}
//                 // placeholder={'0'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Incentive'}
//                 name={'incentive'}
//                 value={formData?.incentive}
//                 handleChange={handleChange}
//                 type={'number'}
//                 min={'0'}
//                 // placeholder={'0'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <div style={{ marginBottom: '5px', fontWeight: 'bold', color: 'black' }}>
//                 Incentive Type
//               </div>
//               <Select
//                 name="incentiveType"
//                 value={formData?.incentiveType}
//                 onChange={handleChange}
//                 fullWidth
//               >
//                 {['%', 'INR']?.map((type) => (
//                   <MenuItem key={type} value={type}>
//                     {type}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </Grid>
//           </Grid>
//         </Box>
//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Date of Joining'}
//                 name={'dateOfJoining'}
//                 value={formData?.dateOfJoining}
//                 handleChange={handleChange}
//                 type={'date'}
//                 max={new Date().toISOString().split('T')[0]}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Upload Photo (max size: 1MB)'}
//                 name={'img1'}
//                 value={formData?.img1 || ' '}
//                 handleChange={handleImage}
//                 type={'file'}
//               />
//             </Grid>
//             <Grid item xs={12} md={4}>
//               <TextInputs
//                 required={false}
//                 title={'Upload Id (max size: 1MB)'}
//                 name={'img2'}
//                 value={formData?.img2 || ' '}
//                 handleChange={handleImage}
//                 type={'file'}
//               />
//             </Grid>
//           </Grid>
//         </Box>

//         <div style={{ width: '200px', margin: '3rem auto' }}>
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <Button
//               variant="contained"
//               fullWidth
//               color="primary"
//               target="_blank"
//               sx={{
//                 background: '#6174DD',
//               }}
//               onClick={handleSubmit}
//             >
//               Add Staff
//             </Button>
//           )}
//         </div>
//       </DashboardCard>
//     </PageContainer>
//   );
// };

// export default AddStaff;

import React, { useState } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import RadioInputs from 'src/components/inputs/RadioInputs';
import imageCompression from 'browser-image-compression';
import TimeSlotSelector from 'src/components/inputs/TimeSlotSelector';
import { useEffect } from 'react';

const initialValue = {
  name: '',
  dob: '',
  phone: '',
  mail: '',
  start: '',
  end: '',
  salary: '',
  emergencyContact: '',
  emergencyName: '',
  gender: '',
  dateOfJoining: '',
  userType: '',
  department: '',
  incentive: '',
  // incentiveType: 'INR', 
  incentiveType: '%',
  target: '',
  img1: null,
  img2: null,
  address: '',
};

const initialFormValidation = {
  nameValidation: false,
  phoneValidation: false,
  genderValidation: false,
  mailValidation: false,
};

const incentive_types = ['%', 'INR'];

const AddStaff = () => {
  const { createStaff, activeBranchId, setShowMessage } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);

  const [loading, setLoading] = useState(false);

  const nameValidation = !formData.name.trim();
  const phoneValidation = !formData.phone || formData.phone.length !== 10;
  const genderValidation = !formData.gender;
  const mailValidation = !formData.mail;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImage = async (e) => {
    const imageFile = e.target?.files[0];

    if (imageFile) {
      const maxSizeInBytes = 1024 * 1024; // 1MB
      const currentSizeInBytes = imageFile.size;

      if (currentSizeInBytes <= maxSizeInBytes) {
        // If the image is already smaller than 1MB, no need to compress
        setFormData({ ...formData, [e.target.name]: imageFile });
      } else {
        // Image needs compression
        try {
          const options = {
            maxSizeMB: 1,
            useWebWorker: true,
          };
          const compressedImage = await imageCompression(imageFile, options);
          setFormData({ ...formData, [e.target.name]: compressedImage });
        } catch (error) {
          setShowMessage({
            message: 'Error compressing image. Please try again.',
            messageType: 'error',
          });
        }
      }
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleIncentiveTypeChange = (event, newIncentiveType) => {
    if (newIncentiveType !== null) {
      setFormData({ ...formData, incentiveType: newIncentiveType });
    }
  };

  const handleSubmit = async () => {
    const {
      name,
      dob,
      phone,
      mail,
      start,
      end,
      salary,
      emergencyContact,
      emergencyName,
      gender,
      dateOfJoining,
      userType,
      department,
      incentive,
      incentiveType,
      target,
      img1,
      img2,
      address,
    } = formData;

    const validation = {
      nameValidation: !name.trim(),
      phoneValidation: !phone || formData.phone.length !== 10,
      genderValidation: !gender,
      mailValidation: !mail,
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    const formDataUpdated = new FormData();
    formDataUpdated.append('name', name);
    formDataUpdated.append('dob', dob);
    formDataUpdated.append('gender', gender);
    formDataUpdated.append('phone', phone);
    formDataUpdated.append('mail', mail);
    formDataUpdated.append('workingHrs', JSON.stringify({ start, end }));
    formDataUpdated.append('salary', salary);
    formDataUpdated.append(
      'emergencyDetails',
      JSON.stringify({ contactNo: emergencyContact, name: emergencyName }),
    );
    formDataUpdated.append('address', address);
    formDataUpdated.append('dateOfJoining', dateOfJoining);
    formDataUpdated.append('userType', userType);
    formDataUpdated.append('department', department);
    formDataUpdated.append('incentive', incentive);
    formDataUpdated.append('incentiveType', incentiveType);
    formDataUpdated.append('target', target);
    formDataUpdated.append('branchDetails', activeBranchId);
    if (img1) {
      formDataUpdated.append('images', img1);
    }
    if (img2) {
      formDataUpdated.append('images', img2);
    }

    try {
      setLoading(true);
      const res = await createStaff(formDataUpdated);
      if (res?.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Staff created successfully',
          messageType: 'success',
        });
        setFormData((pre) => ({ ...initialValue }));
        setFormValidation((pre) => ({ ...initialFormValidation }));
      } else {
        setShowMessage({ message: res.message || 'Something went wrong', messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({ message: error.message || 'Something went wrong', messageType: 'error' });
    } finally {
      setLoading(false);
    }
  };

  console.log(formData,"sssssssssssssssssss");
  return (
    <PageContainer title="Add Staff" description="this is add Staff">
      <DashboardCard title="Add Staff">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Name'}
                name={'name'}
                value={formData?.name}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Name'}
                fieldValidation={nameValidation && formValidation.nameValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Contact Number'}
                name={'phone'}
                value={formData?.phone}
                handleChange={handleChange}
                type={'number'}
                placeholder={'Mobile No.'}
                fieldValidation={phoneValidation && formValidation.phoneValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                title={'Email Address'}
                name={'mail'}
                value={formData?.mail}
                handleChange={handleChange}
                type={'email'}
                placeholder={'Email'}
                fieldValidation={mailValidation && formValidation.mailValidation}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <RadioInputs
                title={'Gender'}
                name={'gender'}
                value={formData?.gender}
                handleChange={handleChange}
                options={[
                  { title: 'M', value: 'Male' },
                  { title: 'F', value: 'Female' },
                ]}
                fieldValidation={genderValidation && formValidation.genderValidation}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Date of Birth'}
                name={'dob'}
                value={formData?.dob}
                handleChange={handleChange}
                type={'date'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Address'}
                name={'address'}
                value={formData?.address}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Address'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Emergency Contact Number'}
                name={'emergencyContact'}
                value={formData?.emergencyContact}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Emergency Contact Person'}
                name={'emergencyName'}
                value={formData?.emergencyName}
                handleChange={handleChange}
                type={'text'}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Monthly Salary'}
                name={'salary'}
                min={'0'}
                // placeholder={'0'}
                value={formData?.salary}
                handleChange={handleChange}
                type={'number'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TimeSlotSelector
                      required={false}
                      title={'Shift Time'}
                      name={'start'}
                      value={formData?.start}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} mt={3}>
                    <TimeSlotSelector
                      required={false}
                      // title={'end time'}
                      name={'end'}
                      value={formData?.end}
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'User Type'}
                name={'userType'}
                value={formData?.userType}
                handleChange={handleChange}
                type={'text'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Department'}
                name={'department'}
                value={formData?.department}
                handleChange={handleChange}
                type={'text'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Target Amount'}
                name={'target'}
                value={formData?.target}
                min={'0'}
                handleChange={handleChange}
                type={'number'}
              // placeholder={'0'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Incentive'}
                name={'incentive'}
                value={formData?.incentive}
                handleChange={handleChange}
                type={'number'}
                min={'0'}
              // placeholder={'0'}
              />
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <div style={{ marginBottom: '5px', fontWeight: 'bold', color: 'black' }}>
                Incentive Type
              </div>
              <Select
                name="incentiveType"
                value={formData?.incentiveType}
                onChange={handleChange}
                fullWidth
              >
                {['%', 'INR']?.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <div style={{ marginBottom: '6px', fontWeight: 'bold', color: 'black' }}>
                Incentive Type
              </div>
              <ToggleButtonGroup
                value={formData.incentiveType}
                exclusive
                onChange={handleIncentiveTypeChange}
                aria-label="incentive type"
                fullWidth
                sx={{
                  borderRadius: '4px',
                  height: '50px',
                  width:'180px',
                  backgroundColor: '#f0f0f0',
                }}
              >
                {incentive_types.map((type) => (
                  <ToggleButton
                    key={type}
                    value={type}
                    aria-label={type}
                    sx={{
                      color: '#000',
                      backgroundColor: '#e0e0e0',
                      height:'3.4rem',
                      '&.Mui-selected': {
                        backgroundColor: '#6174DD',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#6174DD',
                        },
                      },
                      '&:not(.Mui-selected)': {
                        '&:hover': {
                          backgroundColor: '#d0d0d0',
                        },
                      }
                    }}
                  >
                    {type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Date of Joining'}
                name={'dateOfJoining'}
                value={formData?.dateOfJoining}
                handleChange={handleChange}
                type={'date'}
                max={new Date().toISOString().split('T')[0]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Upload Photo (max size: 1MB)'}
                name={'img1'}
                value={formData?.img1 || ' '}
                handleChange={handleImage}
                type={'file'}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInputs
                required={false}
                title={'Upload Id (max size: 1MB)'}
                name={'img2'}
                value={formData?.img2 || ' '}
                handleChange={handleImage}
                type={'file'}
              />
            </Grid>
          </Grid>
        </Box>

        <div style={{ width: '200px', margin: '3rem auto' }}>
          {loading ? (
            <ShowLoader />
          ) : (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              target="_blank"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              Add Staff
            </Button>
          )}
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddStaff;

