// import React, { useState } from 'react';
// import PageContainer from 'src/components/container/PageContainer';
// import DashboardCard from 'src/components/shared/DashboardCard';
// import { Button, Grid } from '@mui/material';
// import { Box } from '@mui/system';
// import { useMain } from '../hooks/useMain';
// import TextInputs from 'src/components/inputs/TextInputs';
// import ShowLoader from 'src/components/CustomComponents/ShowLoader';
// import SelectInputs from 'src/components/inputs/SelectInputs';
// import { useEffect } from 'react';

// const activeDurationTypeMenuOptions = [
//   { placeholder: 'Days', value: 'Days' },
//   { placeholder: 'Months', value: 'Months' },
// ];

// const initialValue = {
//   branchName: '',
//   branchEmail: '',
//   branchPassword: '',
//   confirmPassword: '',
//   activeDuration: '',
//   activeDurationType: 'Months',
//   address: '',
//   branchPhone: '', // Added branchPhone field
// };

// const initialFormValidation = {
//   branchNameValidation: false,
//   branchEmailValidation: false,
//   branchPasswordValidation: false,
//   confirmPassword: false,
//   activeDurationValidation: false,
// };

// const AddBranch = () => {
//   const { createBranch, setShowMessage, ownerData } = useMain();

//   const [formData, setFormData] = useState(initialValue);
//   const [formValidation, setFormValidation] = useState(initialFormValidation);
//   const [loading, setLoading] = useState(false);

//   const branchNameValidation = !formData.branchName.trim();
//   const branchEmailValidation = !formData.branchEmail.trim();
//   const branchPasswordValidation = !formData.branchPassword.trim();
//   const confirmPasswordValidation = !formData.confirmPassword.trim();
//   const activeDurationValidation =
//     !formData.activeDuration || !(Number(formData.activeDuration) > 0);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async () => {
//     const {
//       branchName,
//       branchEmail,
//       branchPassword,
//       activeDuration,
//       confirmPassword,
//       branchPhone,
//     } = formData;

//     const validation = {
//       branchNameValidation: !branchName.trim(),
//       branchEmailValidation: !branchEmail.trim(),
//       branchPasswordValidation: !branchPassword.trim(),
//       confirmPasswordValidation: !confirmPassword.trim(),
//       activeDurationValidation: !activeDuration || !(Number(activeDuration) > 0),
//     };

//     setFormValidation((pre) => ({ ...pre, ...validation }));

//     if (Object.values(validation).some((item) => item)) return;

//     setLoading(true);

//     try {
//       const res = await createBranch({
//         ...formData,
//         ownerId: ownerData._id,
//       });
//       if (res.statusCode === 200) {
//         setShowMessage({
//           message: res.message || 'Successfully created branch',
//           messageType: 'success',
//         });
//         setFormData(initialValue);
//         setFormValidation(initialFormValidation);
//       } else {
//         setShowMessage({ message: res.message || "Can't create branch", messageType: 'error' });
//       }
//     } catch (error) {
//       setShowMessage({
//         message: error.message || 'Error Occurs creating branch',
//         messageType: 'error',
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   // This logic disables the mouse wheel event
//   useEffect(() => {
//     const handleWheel = (e) => {
//       if (document.activeElement.type === 'number') {
//         document.activeElement.blur();
//       }
//     };

//     window.addEventListener('wheel', handleWheel);
//     return () => {
//       window.removeEventListener('wheel', handleWheel);
//     };
//   }, []);

//   return (
//     <PageContainer title="Add Branch" description="This is Add New Branch">
//       <DashboardCard title="Add New Branch">
//         <Box style={{ marginTop: '20px' }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6}>
//               <TextInputs
//                 title={'Branch Name'}
//                 name={'branchName'}
//                 value={formData?.branchName}
//                 handleChange={handleChange}
//                 type={'text'}
//                 placeholder={'Branch Name'}
//                 fieldValidation={branchNameValidation && formValidation.branchEmailValidation}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <TextInputs
//                 title={'Branch Email'}
//                 name={'branchEmail'}
//                 value={formData?.branchEmail}
//                 handleChange={handleChange}
//                 type={'email'}
//                 placeholder={'Email'}
//                 fieldValidation={branchEmailValidation && formValidation.branchEmailValidation}
//               />
//             </Grid>
//           </Grid>
//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Branch Password'}
//                   name={'branchPassword'}
//                   value={formData?.branchPassword}
//                   handleChange={handleChange}
//                   type={'password'}
//                   fieldValidation={
//                     branchPasswordValidation && formValidation.branchPasswordValidation
//                   }
//                 />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Confirm Password'}
//                   name={'confirmPassword'}
//                   value={formData?.confirmPassword}
//                   handleChange={handleChange}
//                   type={'password'}
//                   fieldValidation={
//                     confirmPasswordValidation && formValidation.confirmPasswordValidation
//                   }
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Contact Number'}
//                   name={'branchPhone'}
//                   value={formData?.branchPhone}
//                   handleChange={handleChange}
//                   type={'number'}
//                   placeholder={'Mobile No.'}
//                   required={false}
//                 />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <TextInputs
//                   title={'Address'}
//                   name={'address'}
//                   value={formData?.address}
//                   handleChange={handleChange}
//                   type={'text'}
//                   placeholder={'Address'}
//                   required={true}
//                   style={{ width: '100%' }}
//                 />
//               </Grid>
//             </Grid>
//           </Box>
//           <Box style={{ marginTop: '20px' }}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={4}>
//                 <Grid container direction="row" spacing={2}>
//                   <Grid item xs={12} sm={6}>
//                     <TextInputs
//                       title={'Active Duration'}
//                       name={'activeDuration'}
//                       value={formData?.activeDuration}
//                       handleChange={handleChange}
//                       type={'number'}
//                       min={'0'}
//                       fieldValidation={
//                         activeDurationValidation && formValidation.activeDurationValidation
//                       }
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <SelectInputs
//                       title={'Active Duration Type'}
//                       name={'activeDurationType'}
//                       value={formData?.activeDurationType}
//                       handleChange={handleChange}
//                       options={activeDurationTypeMenuOptions}
//                       optionTitle={'placeholder'}
//                       optionValue={'value'}
//                     />
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//         <div style={{ width: '200px', margin: '3rem auto' }}>
//           {loading ? (
//             <ShowLoader />
//           ) : (
//             <Button
//               variant="contained"
//               fullWidth
//               color="primary"
//               target="_blank"
//               sx={{
//                 background: '#6174DD',
//               }}
//               onClick={handleSubmit}
//             >
//               Create Branch
//             </Button>
//           )}
//         </div>
//       </DashboardCard>
//     </PageContainer>
//   );
// };

// export default AddBranch;

//*new code
import React, { useState, useEffect } from 'react';
import PageContainer from 'src/components/container/PageContainer';
import DashboardCard from 'src/components/shared/DashboardCard';
import { Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { useMain } from '../hooks/useMain';
import TextInputs from 'src/components/inputs/TextInputs';
import ShowLoader from 'src/components/CustomComponents/ShowLoader';
import { ReqField } from 'src/components/CustomComponents/CustomLoader';

const activeDurationTypeOptions = ['Days', 'Months'];

const initialValue = {
  branchName: '',
  branchEmail: '',
  branchPassword: '',
  confirmPassword: '',
  activeDuration: '',
  activeDurationType: 'Months',
  address: '',
  branchPhone: '', // Added branchPhone field
};

const initialFormValidation = {
  branchNameValidation: false,
  branchEmailValidation: false,
  branchPasswordValidation: false,
  confirmPassword: false,
  activeDurationValidation: false,
};

const AddBranch = () => {
  const { createBranch, setShowMessage, ownerData } = useMain();

  const [formData, setFormData] = useState(initialValue);
  const [formValidation, setFormValidation] = useState(initialFormValidation);
  const [loading, setLoading] = useState(false);

  const branchNameValidation = !formData.branchName.trim();
  const branchEmailValidation = !formData.branchEmail.trim();
  const branchPasswordValidation = !formData.branchPassword.trim();
  const confirmPasswordValidation = !formData.confirmPassword.trim();
  const activeDurationValidation =
    !formData.activeDuration || !(Number(formData.activeDuration) > 0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleActiveDurationTypeChange = (event, newActiveDurationType) => {
    if (newActiveDurationType !== null) {
      setFormData({ ...formData, activeDurationType: newActiveDurationType });
    }
  };

  const handleSubmit = async () => {
    const {
      branchName,
      branchEmail,
      branchPassword,
      activeDuration,
      confirmPassword,
      branchPhone,
    } = formData;

    const validation = {
      branchNameValidation: !branchName.trim(),
      branchEmailValidation: !branchEmail.trim(),
      branchPasswordValidation: !branchPassword.trim(),
      confirmPasswordValidation: !confirmPassword.trim(),
      activeDurationValidation: !activeDuration || !(Number(activeDuration) > 0),
    };

    setFormValidation((pre) => ({ ...pre, ...validation }));

    if (Object.values(validation).some((item) => item)) return;

    setLoading(true);

    try {
      const res = await createBranch({
        ...formData,
        ownerId: ownerData._id,
      });
      if (res.statusCode === 200) {
        setShowMessage({
          message: res.message || 'Successfully created branch',
          messageType: 'success',
        });
        setFormData(initialValue);
        setFormValidation(initialFormValidation);
      } else {
        setShowMessage({ message: res.message || "Can't create branch", messageType: 'error' });
      }
    } catch (error) {
      setShowMessage({
        message: error.message || 'Error Occurs creating branch',
        messageType: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // This logic disables the mouse wheel event
  useEffect(() => {
    const handleWheel = (e) => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    window.addEventListener('wheel', handleWheel);
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  console.log(formData,"data")

  return (
    <PageContainer title="Add Branch" description="This is Add New Branch">
      <DashboardCard title="Add New Branch">
        <Box style={{ marginTop: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextInputs
                title={'Branch Name'}
                name={'branchName'}
                value={formData?.branchName}
                handleChange={handleChange}
                type={'text'}
                placeholder={'Branch Name'}
                fieldValidation={branchNameValidation && formValidation.branchNameValidation}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputs
                title={'Branch Email'}
                name={'branchEmail'}
                value={formData?.branchEmail}
                handleChange={handleChange}
                type={'email'}
                placeholder={'Email'}
                fieldValidation={branchEmailValidation && formValidation.branchEmailValidation}
              />
            </Grid>
          </Grid>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Branch Password'}
                  name={'branchPassword'}
                  value={formData?.branchPassword}
                  handleChange={handleChange}
                  type={'password'}
                  fieldValidation={branchPasswordValidation && formValidation.branchPasswordValidation}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Confirm Password'}
                  name={'confirmPassword'}
                  value={formData?.confirmPassword}
                  handleChange={handleChange}
                  type={'password'}
                  fieldValidation={confirmPasswordValidation && formValidation.confirmPasswordValidation}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Contact Number'}
                  name={'branchPhone'}
                  value={formData?.branchPhone}
                  handleChange={handleChange}
                  type={'number'}
                  placeholder={'Mobile No.'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInputs
                  title={'Address'}
                  name={'address'}
                  value={formData?.address}
                  handleChange={handleChange}
                  type={'text'}
                  placeholder={'Address'}
                  required={true}
                />
              </Grid>
            </Grid>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextInputs
                      title={'Active Duration'}
                      name={'activeDuration'}
                      value={formData?.activeDuration}
                      handleChange={handleChange}
                      type={'number'}
                      min={'0'}
                      fieldValidation={activeDurationValidation && formValidation.activeDurationValidation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: '2px', marginBottom: '5px', fontWeight: 'bold', color: 'black' }}>
                      Active Duration Type <ReqField/>
                    </div>
                    <ToggleButtonGroup
                      value={formData.activeDurationType}
                      exclusive
                      onChange={handleActiveDurationTypeChange}
                      aria-label="active duration type"
                      fullWidth
                      sx={{ 
                        borderRadius: '4px',
                        // borderRadius: '50%',
                        // height: '30px',
                        backgroundColor: '#f0f0f0', 
                        width:'150px',
                      }}
                    >
                      {activeDurationTypeOptions.map((type) => (
                        <ToggleButton
                          key={type}
                          value={type}
                          aria-label={type}
                          sx={{
                            color: '#000', 
                            backgroundColor: '#e0e0e0', 
                            // borderRadius:'50%',

                            height:'3.3rem',
                            // width:'4rem',
                            width:'150px',
                            '&.Mui-selected': {
                              backgroundColor: '#6174DD',
                              color: '#fff', 
                              '&:hover': {
                                backgroundColor: '#6174DD', 
                              },
                            },
                            '&:not(.Mui-selected)': {
                              '&:hover': {
                                backgroundColor: '#d0d0d0', 
                              },
                            }
                          }}
                        >
                          {type}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div style={{ width: '200px', margin: '3rem auto' }}>
          {loading ? (
            <ShowLoader />
          ) : (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              sx={{
                background: '#6174DD',
              }}
              onClick={handleSubmit}
            >
              Create Branch
            </Button>
          )}
        </div>
      </DashboardCard>
    </PageContainer>
  );
};

export default AddBranch;


